import React, {useState, useEffect} from 'react'
import {
  Subheading,
  Card,
  Stack,
  Icon,
  TextStyle,
  Select,
  Button,
  RangeSlider,
  Tooltip,
} from "@shopify/polaris"
import {
  DeleteMajor,
  CircleInformationMajor
} from '@shopify/polaris-icons';
import MulProductPickerModal from '../../../../../components/mulProductPickerModal'
import TagModal from '../../checkout/tagModal';
import ProductTypeModal from '../../checkout/productTypeModal';
// import Customization from './customization';
// import ABTesting from './abTesting'
import styled from 'styled-components'
import update from 'immutability-helper'
import gql from "graphql-tag"
import { useLazyQuery } from 'react-apollo';
// import SmartRulesCard from './smartRulesCard';
import DiscountCart from '../discountCart/discountCart';
import './shopBrain.css';

const GET_COLLECTION_PRODUCTS = gql`
        query Nodes($ids: [ID!]!){
            nodes(ids: $ids) {
            ...on Collection {
                id,
                handle,
                products(first:100){
                    edges {
                        node {
                        id
                        }
                    }
                },
            }
            }
        }
`
const DeleteWrapper = styled.div`
    cursor: pointer;
`
const ProductPicker = styled.div`
    .mockInputContainer-error {
        background-color: var(--p-surface-critical-subdued);
        border-color: var(--p-border-critical);
    }
`
const ExclusionTextWrapper = styled.div`
    span {
      line-height: 36px;
    }
`
const InfoIcon = styled.div`
  svg {
    fill: #00A0AC;
  }
`
function ShopBrain(props) {
  const { host, token, /*versionAnalytics, versionData, setVersionData, version, setVersion,*/ checkoutOfferType, shop, state, setState, productPickerShow, setProductPickerShow, offerType, currency, currencySymbol, /*locale, addSmartRule*/ } = props
  const normalizeShopifyID = (id) => {
    return Number(
      String(id)
        .replace('gid://shopify/ProductVariant/', '')
        .replace('gid://shopify/Product/', '')
    );
  }
  const [productIndex, setProductIndex] = useState(null)
  const [inclusionCollectionPickerVisible, setInclusionCollectionPickerVisible] = useState(false)
  const [collectionPickerVisible, setCollectionPickerVisible] = useState(false)
  const [mulProductPickerVisible, setMulProductPickerVisible] = useState(false)
  const [getInclusionCollectionProducts, { loading: inclusionLoading, error: inclusionError, data: inclusionData }] = useLazyQuery(GET_COLLECTION_PRODUCTS);
  const [getCollectionProducts, { loading, error, data }] = useLazyQuery(GET_COLLECTION_PRODUCTS);

  useEffect(() => {
    if(productIndex !== null && !inclusionLoading && inclusionError === undefined && inclusionData ){
      setState(update(state,{shopBrain:{inclusion:{[productIndex]:{value: {$set: inclusionData.nodes.map(node => {return {id: node.id, handle: node.handle, products:node.products.edges.map(product => normalizeShopifyID(product.node.id))}})}}}}}))
    }
  }, [inclusionData]);
  useEffect(() => {
    if(productIndex !== null && !loading && error === undefined && data ){
      setState(update(state,{shopBrain:{exclusion:{[productIndex]:{value: {$set: data.nodes.map(node => {return {id: node.id, products:node.products.edges.map(product => normalizeShopifyID(product.node.id))}})}}}}}))
    }
  }, [data]);
  const handleInclusionSelectChange = (index, value) => {
    setState(update(state,{shopBrain: {inclusion: { [index]: {setting: {$set: value} , value: {$set: null}}}}}))
  }
  const handleSelectChange = (index, value) => {
    setState(update(state,{shopBrain: {exclusion: { [index]: {setting: {$set: value} , value: {$set: null}}}}}))
  }
  const saveInclusionValue = (index, value) => {
    setState(update(state,{shopBrain:{inclusion:{[index]:{value: {$set: value}}}}}))
  }
  const saveExclusionValue = (index, value) => {
    setState(update(state,{shopBrain:{exclusion:{[index]:{value: {$set: value}}}}}))
  }
  const addAndInclusion = () => {
    if (checkoutOfferType === "autopilot") {
      setState(update(state,{shopBrain:{inclusion:{$push: [{setting: 'collection',value: null}]}}}))
    } else {
      setState(update(state,{shopBrain:{inclusion:{$push: [{setting: 'tags',value: null}]}}}))
    }
  }
  const addAndExclusion = () => {
    setState(update(state,{shopBrain:{exclusion:{$push: [{setting: 'tags',value: null}]}}}))
  }
  const deleteInclusion = (index) => {
    setState(update(state,{shopBrain:{inclusion:{$splice: [[index, 1]]}}}))
  }
  const deleteExclusion = (index) => {
    setState(update(state,{shopBrain:{exclusion:{$splice: [[index, 1]]}}}))
  }

  let inclusionOptions = [
    {label: 'Tag(s)', value: 'tags'},
    {label: 'Collection', value: 'collection'},
  ]
  let exclusionOptions = [
    {label: 'Tag(s)', value: 'tags'},
    {label: 'Product type', value: 'product type'},
    {label: 'Collection', value: 'collection'},
    {label: 'Requires shipping', value: 'requires shipping'},
  ]

  return (
    <div style={{marginTop:'20px', marginBottom:'20px'}}>
      <Card sectioned>
        <div style={{marginBottom: '20px'}}>
          <Subheading>AI INSTRUCTIONS</Subheading>
        </div>
        <div style={{paddingTop: '20px'}}>
          <div>
          {"checkout" === offerType &&
            (<RangeSlider
              label="Set maximum number of products to show:"
              min={1}
              max={10}
              suffix={<p>{state.productNumber}</p>}
              value={state.productNumber}
              onChange={(val) => setState(update(state, { productNumber: { $set: val }}))}
              output
            />)
            }
            <div style={"checkout" === offerType?{marginTop:'20px'}:{}}>
              <Stack wrap={false} alignment="center">
                <Subheading>Only recommend items from</Subheading>
                <InfoIcon>
                  <Tooltip dismissOnMouseOut content="Default is all active products that are in stock">
                    <Icon
                      source={CircleInformationMajor}
                      olor="base" />
                  </Tooltip>
                </InfoIcon>
              </Stack>
              { state?.shopBrain?.inclusion?.map((item, index) => {
                return <div style={{padding: '10px 0'}} key={index}>
                  <Stack wrap={false} alignment="center">
                    <Stack.Item fill>
                      <div>
                        <Stack wrap={false}>
                          <Stack.Item>
                            <ExclusionTextWrapper>
                              <TextStyle variation="subdued">Products with</TextStyle>
                            </ExclusionTextWrapper>
                          </Stack.Item>
                          <Stack.Item>
                            <Select
                              options={inclusionOptions}
                              onChange={(value) => handleInclusionSelectChange(index, value)}
                              value={item.setting}
                            />
                          </Stack.Item>
                          <Stack.Item fill>
                            { 'tags' === item.setting &&
                            <TagModal saveExclusionValue={saveInclusionValue} item={item} index={index}/>
                            }
                            { "collection" === item.setting &&
                            <ProductPicker>
                              <div className={!item.value ? 'mockInputContainer mockInputContainer-error' : 'mockInputContainer'}>
                                <div>
                                  <div
                                    className='mockInput'
                                    onClick={() => {setProductPickerShow('collection');setInclusionCollectionPickerVisible(true)}}
                                  >
                                    {
                                      !item.value ?
                                        <span>Select collections</span> :
                                        <div>
                                          <span>{item.value.length} collections</span>
                                          <span> | Edit </span>
                                        </div>
                                    }
                                  </div>
                                  {'collection' === productPickerShow ?
                                    <MulProductPickerModal
                                      key={"inclusion"+index}
                                      host={host}
                                      shop={shop}
                                      token={token}
                                      resourceType="Collection"
                                      open={inclusionCollectionPickerVisible}
                                      initialSelectionIds={item.value?item.value:[]}
                                      showVariants={false}
                                      onSelect={(selectPayload) => {
                                        const collectionIDs = selectPayload.selection.map(item => item.id)
                                        setProductIndex(index)
                                        getInclusionCollectionProducts({variables:  {ids: collectionIDs}})

                                        if (selectPayload.selection.length === 0) {
                                          if (item.value) {
                                            // Clear selection if one exists
                                            setState(update(state,{shopBrain:{inclusion:{[index]:{value: {$set: null}}}}}))
                                          }
                                          return
                                        }

                                      }}
                                      onClose={() => {setCollectionPickerVisible(false);setProductPickerShow('product')}}
                                    />
                                    :
                                    false
                                  }
                                </div>
                              </div>
                            </ProductPicker>
                            }
                          </Stack.Item>
                        </Stack>
                      </div>
                    </Stack.Item>
                    <div className='deleteIconWrapper'>
                      {index > 0 ?
                        <Stack.Item>
                          <DeleteWrapper onClick={()=>deleteInclusion(index)}>
                            <Icon
                              source={DeleteMajor}
                              color="critical" />
                          </DeleteWrapper>
                        </Stack.Item>
                        :
                        false
                      }
                    </div>
                  </Stack>
                </div>
              })
              }
              <Button primary onClick={() => addAndInclusion()}>
                + AND
              </Button>
            </div>
            <div style={{marginTop:'20px'}}>
              <Subheading>EXclude from recommendations</Subheading>
              { state?.shopBrain?.exclusion.map((item, index) => {
                return <div style={{padding: '10px 0'}} key={index}>
                  <Stack wrap={false} alignment="center">
                    <Stack.Item fill>
                      <Stack wrap={false}>
                        <Stack.Item>
                          <ExclusionTextWrapper>
                            <TextStyle variation="subdued">Exclude products with</TextStyle>
                          </ExclusionTextWrapper>
                        </Stack.Item>
                        <Stack.Item>
                          <Select
                            options={exclusionOptions}
                            onChange={(value) => handleSelectChange(index, value)}
                            value={item.setting}
                          />
                        </Stack.Item>
                        <Stack.Item fill>
                          { 'tags' === item.setting &&
                          <TagModal saveExclusionValue={saveExclusionValue} item={item} index={index}/>
                          }
                          { 'product type' === item.setting &&
                          <ProductTypeModal saveExclusionValue={saveExclusionValue} item={item} index={index}/>
                          }
                          { "collection" === item.setting &&
                          <ProductPicker>
                            <div className={!item.value ? 'mockInputContainer mockInputContainer-error' : 'mockInputContainer'}>
                              <div>
                                <div
                                  className='mockInput'
                                  onClick={() => {setProductPickerShow('collection');setCollectionPickerVisible(true)}}
                                >
                                  {
                                    !item.value ?
                                      <span>Select collections</span> :
                                      <div>
                                        <span>{item.value.length} collections</span>
                                        <span> | Edit </span>
                                      </div>
                                  }
                                </div>
                                {'collection' === productPickerShow ?
                                  <MulProductPickerModal
                                    key={"exclusion"+index}
                                    host={host}
                                    shop={shop}
                                    token={token}
                                    resourceType="Collection"
                                    open={collectionPickerVisible}
                                    initialSelectionIds={item.value?item.value:[]}
                                    showVariants={false}
                                    onSelect={(selectPayload) => {
                                      const collectionIDs = selectPayload.selection.map(item => item.id)
                                      setProductIndex(index)
                                      getCollectionProducts({variables:  {ids: collectionIDs}})

                                      if (selectPayload.selection.length === 0) {
                                        if (item.value) {
                                          // Clear selection if one exists
                                          setState(update(state,{shopBrain:{exclusion:{[index]:{value: {$set: null}}}}}))
                                        }
                                        return
                                      }

                                    }}
                                    onClose={() => {setCollectionPickerVisible(false);setProductPickerShow('product')}}
                                  />
                                  :
                                  false
                                }
                              </div>
                            </div>
                          </ProductPicker>
                          }
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                    <div className='deleteIconWrapper'>
                      {index > 0 ?
                        <Stack.Item>
                          <DeleteWrapper onClick={()=>deleteExclusion(index)}>
                            <Icon
                              source={DeleteMajor}
                              color="critical" />
                          </DeleteWrapper>
                        </Stack.Item>
                        :
                        false
                      }
                    </div>
                  </Stack>
                </div>
              })
              }
              <Button primary onClick={() => addAndExclusion()}>
                + AND
              </Button>
            </div>
          </div>
        </div>

        {"checkout" === offerType && <DiscountCart state={state} setState={setState} currency={currency} currencySymbol={currencySymbol} isAi={true}/>}
      </Card>
    </div>

  )
}

export default ShopBrain;